import { entities } from "byzantine";
import { ContentCard, catcat as cc } from "cerulean";

import styles from "./SavvyMoneyWidget.module.scss";

const SavvyMoneyWidget = () => {
  const url = entities.savvyMoney.useSavvyMoneyUrl(
    entities.savvyMoney.SavvyMoneyViewUrl.WebWidget,
  );

  return url ? (
    <div className={styles.container}>
      <ContentCard kind="elevated">
        <h4 className={cc(["nds-sans", styles.title])}>Credit score</h4>
        <div className={styles.iframeContainer}>
          <iframe
            title="SavvyMoney"
            src={`${url}&linkToAction=${encodeURIComponent(
              `${window.location.origin}/credit_monitor`,
            )}`}
            style={{ width: "338px", height: "337px", border: "none" }}
          />
        </div>
      </ContentCard>
    </div>
  ) : null;
};

export default SavvyMoneyWidget;
